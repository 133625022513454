<template>
    <div class="absolute top-0 w-full p-6 cursor-pointer flex flex-col items-center md:items-start" @click.stop='toggleMenu'>
        <div class="inverted-text font-monument">Mirabella-Morganti</div>
    </div>

    <!-- Overlay -->
    <div class="fixed top-0 left-0 z-40 w-screen h-screen bg-[#1A1919] transition-transform duration-1000 ease-in-out transform"
         @click.stop='closeMenu'
         :class="{'-translate-x-full': !isMenuOpen, 'translate-x-0': isMenuOpen}">

      <div class="p-6 h-full text-left text-[#FFFFFE] grid md:grid-cols-12 gap-2 relative">
        <div class="md:col-span-2 font-monument text-center md:text-left">Mirabella-Morganti</div>
        <div class="md:col-span-8 py-6 md:pt-0 font-monument leading-tight overflow-y-scroll no-scrollbar">
          ist das Grafikdesignstudio von Rebecca Morganti-Pfaffhauser und Daniela Mirabella. Seit 2013 arbeiten wir an vielseitigen Projekten, darunter Visuelle Identitäten und Kampagnen, Websites, Bücher und Magazine. Unsere Projekte zeichnen sich durch visuelles Forschen und spielerische Kompositionsansätze aus, wobei wir häufig Experimente und Zufälle als Gestaltungsmethoden einsetzen. Fest verwurzelt in den Bereichen Kunst und Kultur, hat sich Mirabella–Morganti kontinuierlich weiterentwickelt und verbindet seine printorientierte Herkunft mit modernen Technologien. Mirabella–Morganti legt besonderen Wert auf die Schaffung kraftvoller Symbiosen zwischen allen Medien.
          Plakate von Mirabella–Morganti wurde in den Wettbewerben «100 beste Plakate» ausgezeichnet.

          <br><br>
          Bla bla bla ... noch mehr Text Bla bla bla ... noch mehr Text Bla bla bla ... noch mehr Text Bla bla bla ... noch mehr Text Bla bla bla ... noch mehr Text Bla bla bla ... noch mehr Text Bla bla bla ... noch mehr Text Bla bla bla ... noch mehr Text Bla bla bla ... noch mehr Text Bla bla bla ... noch mehr Text Bla bla bla ... noch mehr Text Bla bla bla ... noch mehr Text
          <br><br>

          Mirabella–Morganti
          <br>Kochstrasse 18
          <br>CH–8004 Zurich
          <br>
          <span class="underline m-mail" ></span>

          <br><br>
          Follow us on instagram
          <br><br>

          <span class="underline"> Kunden (Auswahl) </span>
          <br>
          Human Rights Film Festival Zurich, WWF, Zürcher Hochschule der Künste (ZHdK), Landesmuseum Zürich

          <br><br>
          <span class="underline"> Unterricht </span>
          <br>
          Diese Erfahrung fliesst auch in unsere Unterrichtspraxis ein. An der ZHdK unterrichten wir im Bachelor Design Praxismodule.Als Einzelpersonen sind wir auch in anderen Kursen aktiv, die partizipative und interaktive Formate, kollaborative Projekte und Designgeschichte beinhalten.
        </div>
      </div>
    </div>
</template>


<script setup lang='ts'>
import { ref } from 'vue';

const closeMenu = () => {
  isMenuOpen.value = false;
};

const isMenuOpen = ref(false);
const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
};

</script>

<style scoped>
.inverted-text {
  mix-blend-mode: difference;
  white-space: nowrap;
  vertical-align: top;
  color: white;
  position: absolute;
  z-index: 10;
}

/* Added styles */
.fixed {
  position: fixed;
  overflow-y: auto;
}

.m-mail {
  &:before {
    content: "\0068\0065\006C\006C\006F\0040"; /* "hello@" */
  }
  &:after {
    content: "\006D\0069\0072\0061\0062\0065\006C\006C\0061\002D\006D\006F\0072\0067\0061\006E\0074\0069\002E\0063\0068"; /* "mirabella-morganti.ch" */
  }
}

</style>